import { Center, chakra, Image, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

// import config from '../../../configs/app';
import { getExternalAssetFilePath } from '../../../configs/app/utils';
import useIsMobile from '../../../lib/hooks/useIsMobile';
// import banner from '../../../public/assets/configs/mutuari_desk.png';
// import bannerM from '../../../public/assets/configs/mutuari_mob.png';
import LinkInternal from '../links/LinkInternal';

// import config from 'configs/app';
// import { useAppContext } from 'lib/contexts/app';
// import * as cookies from 'lib/cookies';
//
// import AdBannerContent from './AdBannerContent';

// const feature = config.features.adsBanner;

// const AdBanner = ({ className, isLoading }: { className?: string; isLoading?: boolean }) => {
const AdBanner = () => {
  const lightModeBanner = getExternalAssetFilePath('NEXT_PUBLIC_CUSTOM_ADD_BANNER');
  const darkModeBanner = getExternalAssetFilePath('NEXT_PUBLIC_CUSTOM_ADD_BANNER_DARK');
  const lightModeMobileBanner = getExternalAssetFilePath('NEXT_PUBLIC_CUSTOM_ADD_BANNER_MOBILE');
  const darkModeMobileBanner = getExternalAssetFilePath('NEXT_PUBLIC_CUSTOM_ADD_BANNER_MOBILE_DARK');
  // const addLink = getExternalAssetFilePath('NEXT_PUBLIC_CUSTOM_ADD_LINK');
  const isMobile = useIsMobile();

  const bannerSrc = useColorModeValue(isMobile ? lightModeMobileBanner : lightModeBanner, isMobile ?
    darkModeMobileBanner || lightModeMobileBanner : darkModeBanner || lightModeBanner);
  // const provider = useAppContext().adBannerProvider;
  //
  // const hasAdblockCookie = cookies.get(cookies.NAMES.ADBLOCK_DETECTED, useAppContext().cookies);

  /*if (!feature.isEnabled || hasAdblockCookie === 'true' || !provider) {
    return null;
  }*/

  return (
    <Center
      p={ 4 }
      minH="200px"
      w="100%"
      borderRadius="md"
    >
      <LinkInternal href="https://app.mutuari.io" target="_blank">
        <Image
          src={ bannerSrc }
          objectFit="contain"
          maxW="100%"
          maxH="100%"
          borderRadius={ 4 }
          objectPosition="center"
          alt=""
        />
      </LinkInternal>
    </Center>

  /* <AdBannerContent
      className={ className }
      isLoading={ isLoading }
      provider={ provider }
    />*/
  );
};

export default chakra(AdBanner);
